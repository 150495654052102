const WhatsOnHelper = {

  _eventsEl : document.querySelector('[data-events]'),
  _filterEl : document.querySelector('.events-filter'),
  _hasPlaceFilter : undefined
};


WhatsOnHelper.init = function (hasPlaceFilter) {
  WhatsOnHelper._hasPlaceFilter = hasPlaceFilter;

  document.querySelector('[data-events-fiter-close]').addEventListener('click', WhatsOnHelper.closeActivitiesFilter);

  WhatsOnHelper.addActivities();
};


WhatsOnHelper.addActivities = function () {
  ApiHelper.getAllActivities(WhatsOnHelper._hasPlaceFilter).then(value => {
    WhatsOnHelper.addActivitiesHtml(value);
  });
};

WhatsOnHelper.addActivitiesHtml = function (activities) {
  let eventsHtml = '';

  activities.forEach(activity => {
    const html =
      `<div class="event">
        <h4>
          <a href="${window.location.origin}/atividade/?id=${activity.id}">${activity.fields['Nome']}</a><br>
          ${activity.start_end_hours ? activity.start_end_hours : ''}
        </h4>
        <div class="event-local-details">
          <i class="fas fa-map-marker-alt"></i>
          <p>
            ${activity.fields['Local.Nome'] ? activity.fields['Local.Nome']+'<br>' : ''}
            <a href="${activity.fields['Local.LinkGoogleMaps'] ? activity.fields['Local.LinkGoogleMaps'] : ''}" target="_blank">Abrir no Google Maps <i class="fas fa-external-link-square-alt"></i></a>
          </p>
        </div>
      </div>`;

    eventsHtml += html;
  });

  WhatsOnHelper._eventsEl.innerHTML = eventsHtml;

  if(WhatsOnHelper._hasPlaceFilter)
    WhatsOnHelper.openActivitiesFilter(' '+activities[0].fields['Local.Nome']);
};

WhatsOnHelper.openActivitiesFilter = function (placeName) {
  WhatsOnHelper._filterEl.classList.add('events-filter--open');
  WhatsOnHelper._filterEl.querySelector('[data-filter-name]').innerHTML = placeName;
};

WhatsOnHelper.closeActivitiesFilter = function () {
  WhatsOnHelper._filterEl.classList.remove('events-filter--open');

  WhatsOnHelper.resetEvents();
};

WhatsOnHelper.resetEvents = function () {
  WhatsOnHelper._hasPlaceFilter = '';
  WhatsOnHelper._eventsEl.innerHTML = '';
  WhatsOnHelper.addActivities();

  window.history.pushState({}, '', window.location.origin + window.location.pathname);
};
